import { Action, createReducer, on } from '@ngrx/store';
import { ArtistsState, Artist } from './artists.model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ArtistsActions from './artists.actions';

export const adapter: EntityAdapter<Artist> = createEntityAdapter<Artist>({
    selectId: entity => entity.uid
});

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export const initialState: ArtistsState = adapter.getInitialState({
    isArtistsLoading: false
});

const reducer = createReducer(
    initialState,
    on(
        ArtistsActions.actionFetchAllArtists,
        ArtistsActions.actionUpsertArtist,
        ArtistsActions.actionDeleteArtist,
        ArtistsActions.actionBlockArtist,
        (state) => ({ ...state, isArtistsLoading: true })
    ),
    on(
        ArtistsActions.actionFetchAllArtistsSuccess,
        (state, { payload: { artists } }) => {
            return adapter.addMany(artists, { ...state, isArtistsLoading: false });
        }
    ),
    on(
        ArtistsActions.actionFetchAllArtistsFailure,
        ArtistsActions.actionUpsertArtistFailure,
        ArtistsActions.actionDeleteArtistSuccess,
        ArtistsActions.actionBlockArtistFailure,
        (state) => ({ ...state, isArtistsLoading: false })
    ),
    on(
        ArtistsActions.actionUpsertArtistSuccess,
        (state, { payload: { artist } }) => {
            return adapter.upsertOne(artist, { ...state, isArtistsLoading: false });
        }
    ),
    on(
        ArtistsActions.actionDeleteArtistSuccess,
        (state, { payload: { ids } }) => {
            return adapter.removeMany(ids, { ...state, isArtistsLoading: false });
        }
    ),
    on(
        ArtistsActions.actionBlockArtistSuccess,
        (state, { payload: { ids, status } }) => {
            const updatedArtists = ids.map(id => {
                const artist = state.entities[id];
                artist.blocked = status;
                return artist;
            })
            return adapter.upsertMany(updatedArtists, { ...state, isArtistsLoading: false });
        }
    )
);

export function artistsReducer(
    state: ArtistsState | undefined,
    action: Action
): ArtistsState {
    return reducer(state, action);
}
