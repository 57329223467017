import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : '',
        title    : '',
        translate: '',
        type     : 'group',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                type     : 'item',
                icon     : 'home',
                url      : '/dashboard',
            },
            {
                id       : 'artists',
                title    : 'Artists',
                type     : 'item',
                icon     : 'brush',
                url      : '/artists',
            },
            {
                id       : 'sites/projects',
                title    : 'Sites / Projects',
                type     : 'item',
                icon     : 'comment',
                url      : '/sites',
            },
            {
                id       : 'galleryUpload',
                title    : 'Gallery Upload',
                type     : 'item',
                icon     : 'image',
                url      : '/gallery',
            },
            {
                id       : 'teamMembers',
                title    : 'Team Members',
                type     : 'item',
                icon     : 'supervised_user_circle',
                url      : '/teamMembers',
            },
            {
                id       : 'manageFaq',
                title    : 'Manage Faq',
                type     : 'item',
                icon     : 'email',
                url      : '/faq',
            },
            {
                id       : 'contact',
                title    : 'Contact Inquiry Form',
                type     : 'item',
                icon     : 'phone',
                url      : '/contact',
            },
            {
                id       : 'settings',
                title    : 'Settings',
                type     : 'item',
                icon     : 'settings',
                url      : '/settings',
            },
            {
                id       : 'logout',
                title    : 'Logout',
                type     : 'item',
                icon     : 'exit_to_app',
                function : (event) => event.authService.logOut(),   
            },
        ]
    }
];
