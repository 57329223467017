import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, TeamMembersState } from './team-members.model';
import { selectAll } from './team-members.reducer';

export const selectTeamMembersState = createFeatureSelector<
    State,
    TeamMembersState
>('teamMembers');

export const selectAllTeamMembers = createSelector(
    selectTeamMembersState,
    selectAll
);

export const selectIsTeamMembersLoading = createSelector(
    selectTeamMembersState,
    (state) => state.isTeamMembersLoading
);
