import { Injectable } from '@angular/core';
import { AuthState, AUTH_USER } from '../auth/store/auth.model';

const APP_PREFIX = 'Chitranagari-';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor() { }

  static loadAllState() {
    if (sessionStorage.getItem(APP_PREFIX + AUTH_USER)) {
      const user = JSON.parse(sessionStorage.getItem(APP_PREFIX + AUTH_USER));

      const authState: AuthState = {
        isAuthenticated: true,
        isAuthenticating: false,
        user,
        error: null
      };

      const state = {
        auth: authState, // auth state,
      };
      return state;
    } else {
      return null;
    }
  }

  setItem(key: string, value: any) {
    sessionStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  getItem(key: string) {
    return JSON.parse(sessionStorage.getItem(`${APP_PREFIX}${key}`));
  }

  removeItem(key: string) {
    sessionStorage.removeItem(`${APP_PREFIX}${key}`);
  }
}
