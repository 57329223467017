import { createAction, props } from '@ngrx/store';
import { TeamMember, TEAM_MEMBER_UPSERT_TYPES } from './team-members.model';

export const actionFetchAllTeamMembers = createAction(
    '[Team Member] Fetch All Team Members'
);

export const actionFetchAllTeamMembersSuccess = createAction(
    '[Team Member] Fetch All Team Members Success',
    props<{ payload: { teamMembers: TeamMember[] } }>()
);

export const actionFetchAllTeamMembersFailure = createAction(
    '[Team Member] Fetch All Team Members Failure'
);

export const actionUpsertTeamMember = createAction(
    '[Team Member] Upsert Team Member',
    props<{ payload: { teamMember: any, actionType: TEAM_MEMBER_UPSERT_TYPES } }>()
);

export const actionUpsertTeamMemberSuccess = createAction(
    '[Team Member] Upsert Artist Success',
    props<{ payload: { teamMember: TeamMember } }>()
);

export const actionUpsertTeamMemberFailure = createAction(
    '[Team Member] Upsert Team Member Failure'
);

export const actionDeleteTeamMembers = createAction(
    '[Team Member] Delete Team Members',
    props<{ payload: { ids: string[] } }>()
);

export const actionDeleteTeamMembersSuccess = createAction(
    '[Team Member] Delete Team Members Success',
    props<{ payload: { ids: string[] } }>()
);

export const actionDeleteTeamMembersFailure = createAction(
    '[Team Member] Delete Team Members Failure',
);

export const actionBlockTeamMembers = createAction(
    '[Team Member] Block Team Members',
    props<{ payload: { ids: string[], status: boolean } }>()
);

export const actionBlockTeamMembersSuccess = createAction(
    '[Team Member] Block Team Members Success',
    props<{ payload: { ids: string[], status: boolean } }>()
);

export const actionBlockTeamMembersFailure = createAction(
    '[Team Member] Block Team Members Failure',
);
