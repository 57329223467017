import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SitesService } from './sites.service';
import { sitesReducer } from './store/sites.reducer';
import { SitesEffects } from './store/sites.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([SitesEffects]),
    StoreModule.forFeature('sites', sitesReducer)
  ],
  providers: [SitesService]
})
export class SitesModule { }
