import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NegateAuthGuard } from './core/auth/guards/negate-auth.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('./main/authentication/authentication.module').then(m => m.AuthenticationModule),
        canActivate: [NegateAuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'artists',
        loadChildren: () =>
            import('./main/artists/artists.module').then(m => m.ArtistsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'sites',
        loadChildren: () =>
            import('./main/sites/sites.module').then(m => m.SitesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'gallery',
        loadChildren: () =>
            import('./main/gallery/gallery.module').then(m => m.GalleryModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'teamMembers',
        loadChildren: () =>
            import('./main/team-members/team-members.module').then(m => m.TeamMembersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'faq',
        loadChildren: () =>
            import('./main/faq/faq.module').then(m => m.FaqModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'contact',
        loadChildren: () =>
            import('./main/contact/contact.module').then(m => m.ContactModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        loadChildren: () =>
            import('./main/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/dashboard'
    }
];

@NgModule({
    // useHash supports github.io demo page, remove in your app
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
