import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { TeamMembersState, TeamMember } from './team-members.model';
import * as TeamMembersActions from './team-members.actions';

export const adapter: EntityAdapter<TeamMember> = createEntityAdapter<TeamMember>({
    selectId: entity => entity.uid
});

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export const initialState: TeamMembersState = adapter.getInitialState({
    isTeamMembersLoading: false
});

const reducer = createReducer(
    initialState,
    on(
        TeamMembersActions.actionFetchAllTeamMembers,
        TeamMembersActions.actionUpsertTeamMember,
        TeamMembersActions.actionDeleteTeamMembers,
        TeamMembersActions.actionBlockTeamMembers,
        (state) => ({ ...state, isTeamMembersLoading: true })
    ),
    on(
        TeamMembersActions.actionFetchAllTeamMembersSuccess,
        (state, { payload: { teamMembers } }) => {
            return adapter.addMany(teamMembers, { ...state, isTeamMembersLoading: false });
        }
    ),
    on(
        TeamMembersActions.actionFetchAllTeamMembersFailure,
        TeamMembersActions.actionUpsertTeamMemberFailure,
        TeamMembersActions.actionDeleteTeamMembersFailure,
        TeamMembersActions.actionBlockTeamMembersFailure,
        (state) => ({ ...state, isTeamMembersLoading: false })
    ),
    on(
        TeamMembersActions.actionUpsertTeamMemberSuccess,
        (state, { payload: { teamMember } }) => {
            return adapter.upsertOne(teamMember, { ...state, isTeamMembersLoading: false });
        }
    ),
    on(
        TeamMembersActions.actionDeleteTeamMembersSuccess,
        (state, { payload: { ids } }) => {
            return adapter.removeMany(ids, { ...state, isTeamMembersLoading: false });
        }
    ),
    on(
        TeamMembersActions.actionBlockTeamMembersSuccess,
        (state, { payload: { ids, status } }) => {
            const updatedTeamMembers = ids.map(id => {
                const teamMember = state.entities[id];
                teamMember.blocked = status;
                return teamMember;
            })
            return adapter.upsertMany(updatedTeamMembers, { ...state, isTeamMembersLoading: false });
        }
    )
);

export function teamMembersReducer(
    state: TeamMembersState | undefined,
    action: Action
): TeamMembersState {
    return reducer(state, action);
}
