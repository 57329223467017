import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState, selectRouterState } from '../core.state';
import * as AuthActions from './store/auth.actions';
import { selectAccessToken, selectAuthErrors, selectIsAuthenticated, selectIsAuthenticating, selectCurrentUser } from './store/auth.selectors';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
    accessTokenRef: any;
    constructor(private store$: Store<AppState>) { }

    isAuthenticated$ = this.store$.pipe(select(selectIsAuthenticated));
    isAuthenticating$ = this.store$.pipe(select(selectIsAuthenticating));
    getAccessToken$ = this.store$.pipe(select(selectAccessToken));
    getAuthErrors$ = this.store$.pipe(select(selectAuthErrors));
    currentUser$ = this.store$.pipe(select(selectCurrentUser));

    login(username: string, password: string): void {
        this.store$.dispatch(AuthActions.actionLoginStart({ payload: { username, password } }));
    }

    logOut(): void {
        this.store$.dispatch(AuthActions.actionLogoutStart());
    }

    forgotPassword(username: string): void {
        this.store$.dispatch(AuthActions.actionForgotPasswordStart({ payload: { username } }));
    }

    resetPassword(password: string): void {
        this.store$.dispatch(AuthActions.actionSetNewPasswordStart({ payload: { password } }));
    }

    setAuthErrors(error: string): void {
        this.store$.dispatch(AuthActions.actionSetAuthErrors({ payload: { error } }));
    }

    startTimerForAccessToken(): void {
        this.accessTokenRef = window.setInterval(() => {
            this.store$.dispatch(AuthActions.actionGenerateAccessToken());
        }, 55000);
    }

    stopTimerForAccessToken(): void {
        clearInterval(this.accessTokenRef);
    }
}
