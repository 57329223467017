import { SitesState } from './sites.model';
import { Action, createReducer } from '@ngrx/store';

export const initialState: SitesState = {
    isSitesLoading: false
};

const reducer = createReducer(
    initialState
);

export function sitesReducer(
    state: SitesState | undefined,
    action: Action
): SitesState {
    return reducer(state, action);
}
