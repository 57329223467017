import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ArtistsState, State } from './artists.model';
import { selectAll } from './artists.reducer';

export const selectArtistsState = createFeatureSelector<
    State,
    ArtistsState
>('artists');

export const selectAllArtists = createSelector(
    selectArtistsState,
    selectAll
);

export const selectIsArtistsLoading = createSelector(
    selectArtistsState,
    (state) => state.isArtistsLoading
);