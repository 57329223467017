import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class HttpClientService {
    private accessToken: string;
    constructor(private http: HttpClient, private authService: AuthService) {
        this.authService.getAccessToken$.subscribe(token => {
            this.accessToken = token;
        });
    }

    createAuthorizationHeader(): HttpHeaders {
        return new HttpHeaders({
            Authorization: `Bearer ${this.accessToken}`
        });
    }

    get(url: string): any {
        const headers = this.createAuthorizationHeader();
        return this.http
            .get(url, {
                headers
            })
            .pipe(
                catchError(e => {
                    if (e.status === 401) {
                        this.authService.logOut();
                    }
                    return throwError(e);
                })
            );
    }

    post(url: string, data: any): any {
        const headers = this.createAuthorizationHeader();
        return this.http
            .post(url, data, {
                headers
            })
            .pipe(
                catchError(e => {
                    if (e.status === 401) {
                        this.authService.logOut();
                    }
                    return throwError(e);
                })
            );
    }

    put(url: string, data: any): any {
        const headers = this.createAuthorizationHeader();
        return this.http
            .put(url, data, {
                headers
            })
            .pipe(
                catchError(e => {
                    if (e.status === 401) {
                        this.authService.logOut();
                    }
                    return throwError(e);
                })
            );
    }

    delete(url: string): any {
        const headers = this.createAuthorizationHeader();
        return this.http
            .delete(url, {
                headers
            })
            .pipe(
                catchError(e => {
                    if (e.status === 401) {
                        this.authService.logOut();
                    }
                    return throwError(e);
                })
            );
    }
}
