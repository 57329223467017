import { AppState } from '../../core.state';
import { EntityState } from '@ngrx/entity';

export enum MANAGE_ARTIST {
    DELETE = 'delete',
    BLOCK = 'block',
    BLOCK_ALL = 'block all'
}

export enum ARTIST_UPSERT_TYPES {
    CREATE = 'create',
    UPDATE = 'update'
}
export interface Artist {
    uid: string;
    unique_id: string;
    registration_date: string;
    name: string;
    date_of_birth: string;
    primary_number: number;
    secondary_number?: number;
    address: string;
    Photo: string;
    drawing_checked_by: string;
    money_received_by: string;
    blocked: boolean;
    activated: boolean;
}

export interface ArtistsState extends EntityState<Artist> {
    isArtistsLoading: boolean;
}

export interface State extends AppState {
    artists: ArtistsState;
}
