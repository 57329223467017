import { Injectable } from '@angular/core';
import { HttpClientService } from '../http/http-client.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AuthDataService {
    constructor(private http: HttpClientService) { }

    login(username: string, password: string): Observable<any> {
        const url = environment.api.login;
        return this.http.post(url, { username, password });
    }

    forgotPassword(username: string): Observable<any> {
        const url = environment.api.forgotPassword;
        return this.http.post(url, { username });
    }

    generateToken(refreshToken: string): Observable<any> {
        const url = environment.api.token;
        return this.http.post(url, { refresh_token: refreshToken });
    }

    resetPassword(password: string, requestToken: string): Observable<any> {
        const url = environment.api.resetPassword;
        return this.http.post(url, { password, token: requestToken });
    }
}
