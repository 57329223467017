import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DigitOnlyDirective } from './directives/digitonly.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MyDateAdapter } from './helperClasses/MyDateAdapter';

const MY_DATE_FORMATS = {
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
    },
    display: {
        dateInput: 'input',
        monthYearLabel: {year: 'numeric', month: 'short'},
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
 };

@NgModule({
    declarations: [DigitOnlyDirective, SnackbarComponent],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatMenuModule,
        MatDialogModule,
        MatToolbarModule,
        MatSelectModule,
        MatDatepickerModule,
        MatSnackBarModule,
    ],
    exports: [
        DigitOnlyDirective,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatMenuModule,
        MatDialogModule,
        MatToolbarModule,
        MatSelectModule,
        MatDatepickerModule,
        MatSnackBarModule,
        SnackbarComponent
    ],
    providers: [
        {provide: DateAdapter, useClass: MyDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    ],
})
export class SharedModule { }
