import { createAction, props } from "@ngrx/store";
import { User } from './auth.model';

export const actionLoginStart = createAction(
    '[Auth] Login Start',
    props<{ payload: { username: string, password: string } }>()
);

export const actionLoginSuccess = createAction(
    '[Auth] Login Success',
    props<{ payload: { user: User } }>()
);

export const actionLoginFailure = createAction(
    '[Auth] Login Failure',
    props<{ payload: { error: any } }>()
);

export const actionLogoutStart = createAction(
    '[Auth] Logout Start'
);

export const actionLogoutSuccess = createAction(
    '[Auth] Logout Success'
);

export const actionLogoutFailure = createAction(
    '[Auth] Logout Failure'
);

export const actionSetAuthErrors = createAction(
    '[Auth] Set Auth Errors',
    props<{ payload: { error: any } }>()
);

export const actionGenerateAccessToken = createAction(
    '[Auth] Generate Access Token',
);

export const actionGenerateAccessTokenSuccess = createAction(
    '[Auth] Generate Access Token Success',
    props<{ payload: { tokenInfo: any } }>()
);

export const actionGenerateAccessTokenFailure = createAction(
    '[Auth] Generate Access Token Failure'
);

export const actionForgotPasswordStart = createAction(
    '[Auth] Forgot Password Start',
    props<{ payload: { username: string } }>()
);

export const actionForgotPasswordSuccess = createAction(
    '[Auth] Forgot Password Success',
);
export const actionForgotPasswordFailure = createAction(
    '[Auth] Forgot Password Failure',
    props<{ payload: { error: any } }>()
);
export const actionSetNewPasswordStart = createAction(
    '[Auth] Set New Password Start',
    props<{ payload: { password: string } }>()
);
export const actionSetNewPasswordSuccess = createAction(
    '[Auth] Set New Password Success',
);
export const actionSetNewPasswordFailure = createAction(
    '[Auth] Set New Password Failure',
    props<{ payload: { error: any } }>()
);