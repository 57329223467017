import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TeamMembersDataService } from '../team-members.data.service';
import * as TeamMembersActions from './team-members.actions';
import { catchError, map, switchMap, filter } from 'rxjs/operators';
import { TEAM_MEMBER_UPSERT_TYPES } from './team-members.model';

@Injectable()
export class TeamMembersEffects {
    constructor(
        private actions$: Actions,
        private teamMemberDataService: TeamMembersDataService
    ) { }

    fetchAllTeamMembers$ = createEffect(
        () => this.actions$.pipe(
            ofType(TeamMembersActions.actionFetchAllTeamMembers),
            switchMap(() => this.teamMemberDataService.fetchTeamMembers().pipe(
                    map((data: any) => TeamMembersActions.actionFetchAllTeamMembersSuccess({ payload: { teamMembers: data } })),
                    catchError(() => [TeamMembersActions.actionFetchAllTeamMembersFailure()])
                )
            )
        )
    );

    upsertTeamMember$ = createEffect(
        () => this.actions$.pipe(
            ofType(TeamMembersActions.actionUpsertTeamMember),
            map(action => ({ teamMember: action.payload.teamMember, actionType: action.payload.actionType })),
            filter(({ teamMember, actionType }) => !!teamMember && !!actionType),
            switchMap(({ teamMember, actionType }) => {
                const method = actionType === TEAM_MEMBER_UPSERT_TYPES.CREATE
                    ? this.teamMemberDataService.createTeamMember(teamMember)
                    : this.teamMemberDataService.updateTeamMember(teamMember);
                return method.pipe(
                    map((data: any) => TeamMembersActions.actionUpsertTeamMemberSuccess({ payload: { teamMember: data } })),
                    catchError(() => [TeamMembersActions.actionUpsertTeamMemberFailure()])
                );
            })
        )
    );

    deleteTeamMembers$ = createEffect(
        () => this.actions$.pipe(
            ofType(TeamMembersActions.actionDeleteTeamMembers),
            map(action => action.payload.ids),
            filter((ids) => !!ids.length),
            switchMap((ids) => 
                this.teamMemberDataService.deleteTeamMember(ids).pipe(
                    map((data: any) => {
                        if (data.status) {
                            return TeamMembersActions.actionDeleteTeamMembersSuccess({ payload: { ids } });
                        }
                    }),
                    catchError(() => [TeamMembersActions.actionDeleteTeamMembersFailure()])
                )
            )
        )
    );

    blockTeamMembers$ = createEffect(
        () => this.actions$.pipe(
            ofType(TeamMembersActions.actionBlockTeamMembers),
            map(action => ({ ids: action.payload.ids, status: action.payload.status })),
            filter(({ ids }) => !!ids.length),
            switchMap(({ ids, status }) => 
                this.teamMemberDataService.blockTeamMember(ids, status).pipe(
                    map((data: any) => {
                        if (data.status) {
                            return TeamMembersActions.actionBlockTeamMembersSuccess({ payload: { ids, status } });
                        }
                    }),
                    catchError(() => [TeamMembersActions.actionBlockTeamMembersFailure()])
                )
            )
        )
    );
}