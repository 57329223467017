import { Injectable } from '@angular/core';
import { HttpClientService } from '../http/http-client.service';
import { Artist } from './store/artists.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ArtistsDataService {
    constructor(private http: HttpClientService) {}

    createArtist(artist: Partial<Artist>): Observable<any> {
        const url = environment.api.createArtist;
        return this.http.post(url, artist);
    }

    fetchArtists(): Observable<any> {
        const url = environment.api.fetchAllArtists;
        return this.http.get(url);
    }

    updateArtist(artist: Partial<Artist>): Observable<any> {
        const url = environment.api.updateArtist;
        return this.http.put(url, artist);
    }

    deleteArtist(artistIds: string[]): Observable<any> {
        const url = environment.api.deleteArtist;
        return this.http.post(url, { users: artistIds });
    }
    
    blockArtist(artistIds: string[], status: boolean): Observable<any> {
        const url = environment.api.blockArtist;
        return this.http.post(url, { users: artistIds, status });
    }
}