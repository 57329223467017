import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ArtistsDataService } from '../artists.data.service';
import * as ArtistsActions from './artists.actions';
import { switchMap, map, catchError, filter } from 'rxjs/operators';
import { ARTIST_UPSERT_TYPES } from './artists.model';

@Injectable()
export class ArtistsEffects {
    constructor(
        private actions$: Actions,
        private artistsDataService: ArtistsDataService
    ) { }

    fetchAllArtists$ = createEffect(
        () => this.actions$.pipe(
            ofType(ArtistsActions.actionFetchAllArtists),
            switchMap(() => this.artistsDataService.fetchArtists().pipe(
                    map((data: any) => ArtistsActions.actionFetchAllArtistsSuccess({ payload: { artists: data } })),
                    catchError(() => [ArtistsActions.actionFetchAllArtistsFailure()])
                )
            )
        )
    );

    upsertArtist$ = createEffect(
        () => this.actions$.pipe(
            ofType(ArtistsActions.actionUpsertArtist),
            map(action => ({ artist: action.payload.artist, actionType: action.payload.actionType })),
            filter(({ artist, actionType }) => !!artist && !!actionType),
            switchMap(({ artist, actionType }) => {
                const method = actionType === ARTIST_UPSERT_TYPES.CREATE
                    ? this.artistsDataService.createArtist(artist)
                    : this.artistsDataService.updateArtist(artist);
                return method.pipe(
                    map((data: any) => ArtistsActions.actionUpsertArtistSuccess({ payload: { artist: data } })),
                    catchError(() => [ArtistsActions.actionUpsertArtistFailure()])
                );
            })
        )
    );

    deleteArtists$ = createEffect(
        () => this.actions$.pipe(
            ofType(ArtistsActions.actionDeleteArtist),
            map(action => action.payload.ids),
            filter((ids) => !!ids.length),
            switchMap((ids) => 
                this.artistsDataService.deleteArtist(ids).pipe(
                    map((data: any) => {
                        if (data.status) {
                            return ArtistsActions.actionDeleteArtistSuccess({ payload: { ids } });
                        }
                    }),
                    catchError(() => [ArtistsActions.actionDeleteArtistFailure()])
                )
            )
        )
    );

    blockArtists$ = createEffect(
        () => this.actions$.pipe(
            ofType(ArtistsActions.actionBlockArtist),
            map(action => ({ ids: action.payload.ids, status: action.payload.status })),
            filter(({ ids }) => !!ids.length),
            switchMap(({ ids, status }) => 
                this.artistsDataService.blockArtist(ids, status).pipe(
                    map((data: any) => {
                        if (data.status) {
                            return ArtistsActions.actionBlockArtistSuccess({ payload: { ids, status } });
                        }
                    }),
                    catchError(() => [ArtistsActions.actionBlockArtistFailure()])
                )
            )
        )
    );
}
