import { AppState } from '../../core.state';

export const AUTH_USER = 'user';

enum USER_TYPE {
    ADMIN = 'ADMIN',
    TEAM_MEMBER = 'TEAM_MEMBER',
    ARTIST = 'ARTIST'
}

export interface User {
    uid: string;
    username: string;
    access_token: string;
    refresh_token: string;
    token_expires: string;
    user_type: USER_TYPE;
    activated: boolean;
    blocked: boolean;
}

export interface AuthState {
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    user: User;
    error: any;
}

export interface State extends AppState {
    auth: AuthState;
}
