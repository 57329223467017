import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, AuthState } from './auth.model';

export const selectAuthState = createFeatureSelector<
    State,
    AuthState
>('auth');

export const selectIsAuthenticated = createSelector(
    selectAuthState,
    (state) => state.isAuthenticated
);

export const selectIsAuthenticating = createSelector(
    selectAuthState,
    (state) => state.isAuthenticating
);

export const selectAccessToken = createSelector(
    selectAuthState,
    (state) => state.user ? state.user.access_token : null
);

export const selectAuthErrors = createSelector(
    selectAuthState,
    (state) => state.error
);

export const selectCurrentUser = createSelector(
    selectAuthState,
    (state) => state.user
);

export const selectCurrentUserId = createSelector(
    selectAuthState,
    (state) => state.user ? state.user.uid : null
);
