// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const endPoint = 'https://api.chitranagari.in';
export const environment = {
    production: false,
    hmr       : false,
    api: {
        login: `${endPoint}/login`,
        forgotPassword: `${endPoint}/forgot-password`,
        token: `${endPoint}/token`,
        resetPassword: `${endPoint}/reset-password`,
        fetchAllArtists: `${endPoint}/get-artists`,
        createArtist: `${endPoint}/create-artist`,
        updateArtist: `${endPoint}/update-artist`,
        deleteArtist: `${endPoint}/delete-artists`,
        blockArtist: `${endPoint}/block-artists`,
        fetchAllTeamMembers: `${endPoint}/get-team-members`,
        createTeamMember: `${endPoint}/create-team-member`,
        updateTeamMember: `${endPoint}/update-team-member`,
        deleteTeamMember: `${endPoint}/delete-team-members`,
        blockTeamMember: `${endPoint}/block-team-members`,
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
