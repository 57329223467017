import { AppState } from '../../core.state';
import { EntityState } from '@ngrx/entity';

export interface Permissions {
    artists: {
        view: boolean;
        create: boolean;
        edit: boolean;
        delete: boolean;
    };
    sites: {
        view: boolean;
        create: boolean;
        edit: boolean;
        delete: boolean;
    };
    gallery: {
        view: boolean;
        create: boolean;
        edit: boolean;
        delete: boolean;
    };
    team_members: {
        view: boolean;
        create: boolean;
        edit: boolean;
        delete: boolean;
    };
    faq: {
        view: boolean;
        create: boolean;
        edit: boolean;
        delete: boolean;
    };
    contact_inquiry: {
        view: boolean;
    };
}

export enum MANAGE_TEAM_MEMBER {
    DELETE = 'delete',
    BLOCK = 'block',
    BLOCK_ALL = 'block all'
}

export enum TEAM_MEMBER_UPSERT_TYPES {
    CREATE = 'create',
    UPDATE = 'update'
}

export interface TeamMember {
    uid: string;
    name: string;
    designation: string;
    date_of_birth: string;
    primary_number: number;
    secondary_number?: number;
    email: string;
    photo_url: string;
    facebook_link?: string;
    instagram_link?: string;
    permissions: Permissions;
    blocked: boolean;
    activated: boolean;
}

export interface TeamMembersState extends EntityState<TeamMember> {
    isTeamMembersLoading: boolean;
}

export interface State extends AppState {
    teamMembers: TeamMember;
}
