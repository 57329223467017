import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: any): string {
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
        } else {
            return date.toDateString();
        }
    }
 
    private _to2digit(n: number): any {
        return ('00' + n).slice(-2);
    } 
 }