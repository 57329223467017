import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ArtistsEffects } from './store/artists.effects';
import { artistsReducer } from './store/artists.reducer';
import { ArtistsService } from './artists.service';
import { ArtistsDataService } from './artists.data.service';



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([ArtistsEffects]),
        StoreModule.forFeature('artists', artistsReducer)
    ],
    providers: [ArtistsService, ArtistsDataService]
})
export class ArtistsModule { }
