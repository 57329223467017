import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './core.state';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomSerializer } from './router/custom-serializer';
import { AuthModule } from './auth/auth.module';
import { environment } from 'environments/environment';
import { ArtistsModule } from './artists/artists.module';
import { TeamMembersModule } from './team-members/team-members.module';
import { SitesModule } from './sites/sites.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
        name: 'Chitranagari'
      }),
    AuthModule,
    ArtistsModule,
    SitesModule,
    TeamMembersModule
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
