import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TeamMembersEffects } from './store/team-members.effects';
import { teamMembersReducer } from './store/team-members.reducer';
import { TeamMembersService } from './team-members.service';
import { TeamMembersDataService } from './team-members.data.service';



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([TeamMembersEffects]),
        StoreModule.forFeature('teamMembers', teamMembersReducer)
    ],
    providers: [TeamMembersService, TeamMembersDataService]
})
export class TeamMembersModule { }
