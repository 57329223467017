import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../core.state';
import { selectAllTeamMembers, selectIsTeamMembersLoading } from './store/team-members.selectors';
import * as TeamMembersActions from './store/team-members.actions';
import { TEAM_MEMBER_UPSERT_TYPES } from './store/team-members.model';

@Injectable()
export class TeamMembersService {
    constructor(
        private store$: Store<AppState>
    ) { }

    allTeamMembers$ = this.store$.pipe(select(selectAllTeamMembers));
    isTeamMemberLoading$ = this.store$.pipe(select(selectIsTeamMembersLoading));

    fetchAllTeamMembers(): void {
        this.store$.dispatch(TeamMembersActions.actionFetchAllTeamMembers());
    }

    createTeamMember(teamMember: any): void {
        this.store$.dispatch(TeamMembersActions.actionUpsertTeamMember(
            { payload: { teamMember, actionType: TEAM_MEMBER_UPSERT_TYPES.CREATE } })
        );
    }

    updateTeamMember(teamMember: any): void {
        this.store$.dispatch(TeamMembersActions.actionUpsertTeamMember(
            { payload: { teamMember, actionType: TEAM_MEMBER_UPSERT_TYPES.UPDATE } })
        );
    }

    deleteTeamMembers(ids: string[]): void {
        this.store$.dispatch(TeamMembersActions.actionDeleteTeamMembers({ payload: { ids } }));
    }

    blockTeamMembers(ids: string[], status: boolean): void {
        this.store$.dispatch(TeamMembersActions.actionBlockTeamMembers({ payload: { ids, status } }));
    }
}
