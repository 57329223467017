import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../core.state';
import { selectAllArtists, selectIsArtistsLoading } from './store/artists.selectors';
import * as ArtistsActions from './store/artists.actions';
import { Artist, ARTIST_UPSERT_TYPES } from './store/artists.model';

@Injectable()
export class ArtistsService {
    constructor(
        private store$: Store<AppState>
    ) {}

    allArtists$ = this.store$.pipe(select(selectAllArtists));
    isArtistsLoading$ = this.store$.pipe(select(selectIsArtistsLoading));

    fetchAllArtists(): void {
        this.store$.dispatch(ArtistsActions.actionFetchAllArtists());
    }

    createArtists(artist: any): void {
        this.store$.dispatch(ArtistsActions.actionUpsertArtist({ payload: { artist, actionType: ARTIST_UPSERT_TYPES.CREATE } }));
    }

    updateArtists(artist: any): void {
        this.store$.dispatch(ArtistsActions.actionUpsertArtist({ payload: { artist, actionType: ARTIST_UPSERT_TYPES.UPDATE } }));
    }

    deleteArtists(ids: string[]): void {
        this.store$.dispatch(ArtistsActions.actionDeleteArtist({ payload: { ids } }));
    }

    blockArtists(ids: string[], status: boolean): void {
        this.store$.dispatch(ArtistsActions.actionBlockArtist({ payload: { ids, status } }));
    }
}
