import { createAction, props } from '@ngrx/store';
import { ARTIST_UPSERT_TYPES, Artist } from './artists.model';

export const actionFetchAllArtists = createAction(
    '[Artists] Fetch All Artist'
);

export const actionFetchAllArtistsSuccess = createAction(
    '[Artists] Fetch All Artist Success',
    props<{ payload: { artists: Artist[] } }>()
);

export const actionFetchAllArtistsFailure = createAction(
    '[Artists] Fetch All Artist Failure'
);

export const actionUpsertArtist = createAction(
    '[Artists] Upsert Artist',
    props<{ payload: { artist: any, actionType: ARTIST_UPSERT_TYPES } }>()
);

export const actionUpsertArtistSuccess = createAction(
    '[Artists] Upsert Artist Success',
    props<{ payload: { artist: Artist } }>()
);

export const actionUpsertArtistFailure = createAction(
    '[Artists] Upsert Artist Failure'
);

export const actionDeleteArtist = createAction(
    '[Artists] Delete Artists',
    props<{ payload: { ids: string[] } }>()
);

export const actionDeleteArtistSuccess = createAction(
    '[Artists] Delete Artists Success',
    props<{ payload: { ids: string[] } }>()
);

export const actionDeleteArtistFailure = createAction(
    '[Artists] Delete Artists Failure',
);

export const actionBlockArtist = createAction(
    '[Artists] Block Artists',
    props<{ payload: { ids: string[], status: boolean } }>()
);

export const actionBlockArtistSuccess = createAction(
    '[Artists] Block Artists Success',
    props<{ payload: { ids: string[], status: boolean } }>()
);

export const actionBlockArtistFailure = createAction(
    '[Artists] Block Artists Failure',
);