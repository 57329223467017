import { Injectable } from '@angular/core';
import { AuthDataService } from '../auth.data.service';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { map, filter, switchMap, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { User, AUTH_USER } from './auth.model';
import { Router } from '@angular/router';
import { SessionStorageService } from 'app/core/session-storage/session-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/main/shared/components/snackbar/snackbar.component';
import { Store, select } from '@ngrx/store';
import { AppState, selectRouterState } from 'app/core/core.state';
import { AuthService } from '../auth.service';
import { selectCurrentUser, selectCurrentUserId } from './auth.selectors';

@Injectable()
export class AuthEffects {
    constructor(
        private authDataService: AuthDataService,
        private store$: Store<AppState>,
        private actions$: Actions,
        private router: Router,
        private sessionStorageService: SessionStorageService,
        private _snackBar: MatSnackBar,
        private authService: AuthService
    ) { }

    loginStart$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.actionLoginStart),
            map(action => action.payload),
            filter(({ username, password }) => !!username && !!password),
            switchMap(({ username, password }) =>
                this.authDataService.login(username, password).pipe(
                    switchMap((data: User) => {
                        this.sessionStorageService.setItem(AUTH_USER, data);
                        return [
                            AuthActions.actionLoginSuccess({ payload: { user: data } }),
                            AuthActions.actionSetAuthErrors({ payload: { error: null } })
                        ];
                    }),
                    catchError((err: any) => [AuthActions.actionLoginFailure({ payload: { error: err.error } })])
                )
            )
        ),
    );

    logOutStart$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.actionLogoutStart),
            map(() => {
                this.sessionStorageService.removeItem(AUTH_USER);
                this.authService.stopTimerForAccessToken();
                return AuthActions.actionLogoutSuccess();
            })
        ),
    );

    generateTokenAtInterval$ = createEffect(
        () => this.store$.pipe(
            select(selectCurrentUserId),
            filter((currentUserId) => !!currentUserId),
            switchMap((currentUserId) => {
                // TODO: Find better solution for this
                const userInfo = this.sessionStorageService.getItem(AUTH_USER);
                return this.authDataService.generateToken(userInfo.refresh_token).pipe(
                    map(data => {
                        this.authService.startTimerForAccessToken();
                        this.sessionStorageService.setItem(AUTH_USER, { ...userInfo, ...data });
                        return AuthActions.actionGenerateAccessTokenSuccess({ payload: { tokenInfo: data } });
                    }),
                    catchError(() => [AuthActions.actionGenerateAccessTokenFailure()])   
                );
            })
        )
    );

    forgotPassword$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.actionForgotPasswordStart),
            map(action => action.payload),
            filter(({ username }) => !!username),
            switchMap(({ username }) =>
                this.authDataService.forgotPassword(username).pipe(
                    switchMap((status) => {
                        this._snackBar.openFromComponent(SnackbarComponent, {
                            duration: 3000,
                            data: {
                                message: 'Password reset link has been sent successfully.',
                            }
                        });
                        return [
                            AuthActions.actionForgotPasswordSuccess(),
                            AuthActions.actionSetAuthErrors({ payload: { error: null } })
                        ];
                    }),
                    catchError((err) => [AuthActions.actionForgotPasswordFailure({ payload: { error: err.error } })])
                )
            )
        )
    );

    resetPassword$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.actionSetNewPasswordStart),
            map(action => action.payload),
            withLatestFrom(
                this.store$.pipe(select(selectRouterState))
            ),
            filter(([{ password }, { state: { queryParams } }]) => !!password && !!queryParams.request_token),
            switchMap(([{ password }, { state: { queryParams } }]) =>
                this.authDataService.resetPassword(password, queryParams.request_token).pipe(
                    switchMap((status) => {
                        this._snackBar.openFromComponent(SnackbarComponent, {
                            duration: 3000,
                            data: {
                                message: 'Password has been updated.',
                            }
                        });
                        return [
                            AuthActions.actionSetNewPasswordSuccess(),
                            AuthActions.actionSetAuthErrors({ payload: { error: null } })
                        ];
                    }),
                    catchError((err) => [AuthActions.actionSetNewPasswordFailure({ payload: { error: err.error } })])
                )
            )
        ),
    );

    generateAccessToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.actionGenerateAccessToken),
            withLatestFrom(
                this.store$.pipe(select(selectCurrentUser))
            ),
            filter(([, currentUser]) => !!currentUser),
            switchMap(([, { refresh_token }]) => 
                this.authDataService.generateToken(refresh_token).pipe(
                    map(data => {
                        const userInfo = this.sessionStorageService.getItem(AUTH_USER);
                        this.sessionStorageService.setItem(AUTH_USER, { ...userInfo, ...data });
                        return AuthActions.actionGenerateAccessTokenSuccess({ payload: { tokenInfo: data } });
                    }),
                    catchError(() => [AuthActions.actionGenerateAccessTokenFailure()])
                )
            )
        ),
    );

    navigationOnLoginSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.actionLoginSuccess),
            map(action => action.payload),
            tap(() => {
                this.router.navigate(['/dashboard']);
            })
        ),
        { dispatch: false }
    );

    navigationOnLogOutSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(AuthActions.actionLogoutSuccess, AuthActions.actionForgotPasswordSuccess, AuthActions.actionSetNewPasswordSuccess),
            tap(() => {
                this.router.navigate(['/auth']);
            })
        ),
        { dispatch: false }
    );
}
