import { Injectable } from '@angular/core';
import { HttpClientService } from '../http/http-client.service';
import { TeamMember } from './store/team-members.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class TeamMembersDataService {
    constructor(private http: HttpClientService) { }

    createTeamMember(teamMember: Partial<TeamMember>): Observable<any> {
        const url = environment.api.createTeamMember;
        return this.http.post(url, teamMember);
    }

    fetchTeamMembers(): Observable<any> {
        const url = environment.api.fetchAllTeamMembers;
        return this.http.get(url);
    }

    updateTeamMember(teamMember: Partial<TeamMember>): Observable<any> {
        const url = environment.api.updateTeamMember;
        return this.http.put(url, teamMember);
    }

    deleteTeamMember(teamMembersIds: string[]): Observable<any> {
        const url = environment.api.deleteTeamMember;
        return this.http.post(url, { users: teamMembersIds });
    }

    blockTeamMember(teamMembersIds: string[], status: boolean): Observable<any> {
        const url = environment.api.blockTeamMember;
        return this.http.post(url, { users: teamMembersIds, status });
    }
}