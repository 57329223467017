import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[digitOnly]'
})
export class DigitOnlyDirective {
    /* The scenario where the user is entering a special character,
    after valid digits is handled by the way parseInt(, 10) function behaves.
    If passes 12; to parseInt, it'll return 12 and thus, the else block will
    suffice our requirement. */
    @HostListener('input', ['$event'])
    onChange(evt) {
        const inputValue = parseInt(evt.target.value, 10);
        if (isNaN(inputValue)) {
            evt.target.value = '';
        } else {
            evt.target.value = `${inputValue}`;
        }
    }
}
